<template>
  <!-- TODO O TEMPLATE FEITO UTILIZANDO O BOOTSTRAP VUE -->
  <div class="productslist">
    <b-button class="returnOrder" @click="movement('dashboard')">Dashboard</b-button>
    <b-button class="lostedOrder" @click="movement('logs')">Logs</b-button>
    <br />
    <br />
    <div v-if="page == 'logs'">
      <h1>Logs</h1>
      <div class="row" id="filters">
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Utilizador</span>
            </div>
            <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="nameSearch" @change="filterLogs()" />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">ID order</span>
            </div>
            <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="objectSearch" @change="filterLogs()" />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Data</span>
            </div>
            <input type="date" class="form-control" id="date" v-model="date" @change="filterLogs()" />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Tipo</span>
            </div>
            <v-select :options="types" v-model="typeSearch" label="types" @input="filterLogs()">
              <template slot="option" slot-scope="option"> {{ option.types }}</template>
            </v-select>
          </div>
        </div>
      </div>

      <br />
      <!-- PAGINAÇÃO NA PARTE SUPERIOR DA TABELA -->
      <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage" aria-controls="my-table" align="center"></b-pagination>
      <!-- TABELA COM TODOS OS PRODUTOS -->
      <div class="table">
        <b-table striped hover :items="allLogs" :fields="fields" :per-page="perPage" :current-page="currentPage" thead-class="text-white" small>
          <template #cell(date)="data">
            {{ splitDate(data.item.date) }}
          </template>
        </b-table>
      </div>
      <!-- PAGINAÇÃO NO INFERIO DA TABELA -->
      <b-pagination class="customPagination" v-model="currentPage" pills :total-rows="rows" :per-page="perPage" aria-controls="my-table" align="center"></b-pagination>
    </div>
    <div v-else-if="page == 'dashboard'">
      <h1>Dashboard</h1>
      <div class="table">
        <b-table striped hover :items="dashboard" :fields="fields2" :per-page="perPage" :current-page="currentPage" thead-class="text-white" small>
          <template #cell(failed)="data">
            <span v-if="data.item.failed == true" class="dot-red"></span>
            <span v-else-if="data.item.failed == false" class="dot-green"></span>
          </template>
          <template #cell(last_update)="data">
            {{ splitDate(data.item.last_update) }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      page: '',
      perPage: 50,
      currentPage: 1,
      criteria: '',
      products: [],
      rows: 1,
      objectSearch: '',
      nameSearch: '',
      typeSearch: '',
      date: '',
      allLogs: [],
      dashboard: [],
      types: ['admin', 'clients', 'order', 'product'],
      fields: [
        {
          key: 'username',
          label: 'Utilizador',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'task',
          label: 'Tarefa',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'object_id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'type',
          label: 'Tipo',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
      fields2: [
        {
          key: 'cron',
          label: 'Processo',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'failed',
          label: 'Status',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'last_update',
          label: 'Último Update',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    movement(new_page) {
      this.page = new_page;
    },
    /* FUNÇÃO ASSINCRONA QUE VAI À STORE BUSCAR OS PRODUTOS */
    async filterLogs() {
      if (this.objectSearch == '' && this.nameSearch == '' && this.date == '') {
        console.log('Não pesquisa');
        this.allLogs = [];
      } else {
        console.log(this.date, this.nameSearch, this.objectSearch);

        try {
          await this.$store.dispatch('getLogs', {
            date: this.date,
            user: this.nameSearch,
            object_id: this.objectSearch,
            type: this.typeSearch,
          });
          this.allLogs = this.getLogs;
          this.perPage = 50;
          this.rows = this.allLogs.length;
        } catch (err) {
          console.log(this.date, this.nameSearch, this.objectSearch);
          console.log(err);
          alert(err);
        }
      }
    },
    async getDashboardFun() {
      try {
        await this.$store.dispatch('getDashboard');
        this.dashboard = this.getDashboard;
        this.perPage = this.dashboard.length;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    splitDate(date) {
      let split = date.split('T');
      let split2 = split[1].split('.');
      return split[0] + ' ' + split2[0];
    },
  },
  computed: {
    ...mapGetters(['getLogs']),
    ...mapGetters(['getDashboard']),
  },
  async created() {
    await this.getDashboardFun();
    this.page = 'dashboard';
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    /*  this.getAllProducts(); */
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}
#filters {
  padding: 0px 100px 0px 100px;
}
.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}

.returnOrder {
  margin: 30px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.returnOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}
.lostedOrder {
  margin: 30px 5px 0px 5px;
  background-color: #7f9db6;
  border-color: #7f9db6;
}
.lostedOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
</style>
