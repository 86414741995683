<template>
  <div>
    <h1>Printing</h1>
    <b-button class="print" @click="imprimir()">Imprimir Selecionadas</b-button>
    <b-button class="print" @click="print_all()">Imprimir Todas</b-button>
    <b-button class="advance" @click="advanceToPacking()">Packing Selecionadas</b-button>
    <b-button class="advance" @click="advance_all()">Packing Todas</b-button>
    <b-table ref="picking" striped hover :items="printingOrders" :fields="fields" :filter="filter" :per-page="perPage" :current-page="currentPage" class="table" small select-mode="multi" thead-class="text-white">
      <template #cell(id)="data">
        <b-form-checkbox @change="checked(data.index, data.rowSelected)" v-model="data.rowSelected" ref="select_checkbox"> </b-form-checkbox>
        <b-link @click="routeToOrder(data.item.id)">
          <span v-if="data.item.priority == true" style="background-color: green; color: white">{{ data.item.id }}</span>
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span v-if="calculateDifferenceBetweenDates(data.item.date) == 2" style="background: blue; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3" style="background: orange; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4" style="background: red; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4" style="background: black; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span v-for="article in JSON.parse(data.item.articles)" :key="article.id">
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a id="openArticle" v-b-modal.modal-3 @click="passProductData(article)">{{ article.name }} </a>
            - {{ article.price_eur }} € -
            <a v-if="article.picked == article.ordered">(✔️)</a>
            <a v-else style="color: red"
              >(Pick: {{ article.picked }} |<a style="color: black">| Stock: {{ article.stock }}</a
              >)</a
            >
          </p>
        </span>
        <a id="picking" @click="back_Picking(data.item.id)">Voltar para picking</a>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                      id="btnUpdateAddress"
                      v-b-modal.modal-1
                      @click="passDataModal(data.item)"
                      >✒️</b-button
                      > -->
            <a id="aUpdateAddress" v-b-modal.modal-1 @click="passDataModal(data.item)">✒️</a>
            <br />
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a id="aUpdateAddress" v-b-modal.modal-2 @click="passContact(data.item)">📱</a>
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data: function () {
    return {
      page: '',
      rows: '',
      perPage: '',
      currentPage: 1,
      filter: null,
      printingOrders: [],
      printOrders: [],
      fields: [
        {
          key: 'id',
          label: 'Nº Encmd.',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
        },
        {
          key: 'articles',
          label: 'Artigos',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'client',
          label: 'Cliente',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'company',
          label: 'Trans.',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
      status: 'all',
      select_print: '',
      transportadora: '',
    };
  },
  methods: {
    //Gets
    async getOrdersPrintingFun() {
      try {
        await this.$store.dispatch('getOrdersPrinting');
        this.printingOrders = this.getOrdersPrinting;
        this.rows = this.printingOrders.length;
        this.perPage = this.printingOrders.length;
      } catch (err) {
        console.log(err);
      }
    },

    //Core functions
    routeToOrder(orderId) {
      this.$parent.routeToOrder(orderId);
    },
    calculateDifferenceBetweenDates(date) {
      let diff = this.$parent.calculateDifferenceBetweenDates(date);
      return Number(diff);
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },

    //Modais
    async passDataModal(data) {
      await this.$parent.passDataModal(data);
    },
    async passContact(data) {
      await this.$parent.passContact(data);
    },
    async passProductData(data) {
      await this.$parent.passProductData(data);
    },
    async confirmPendent(data) {
      await this.$parent.confirmPendent(data);
    },
    async confirmPriority(data) {
      await this.$parent.confirmPriority(data);
    },
    //Specific functions
    async checked(index, checked) {
      if (checked) {
        this.printOrders.push(this.printingOrders[index]);
      } else {
        let index1 = await this.printOrders.findIndex((order) => order.id == this.printingOrders[index].id);
        if (index1 != -1) this.printOrders.splice(index1, 1);
      }
    },
    async print_all() {
      this.$swal({
        title: 'Quer reimprimir todas as encomendas?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.printOrders = [];
          this.printOrders = this.printingOrders;
          await this.imprimir(true);
        }
      });
    },
    async imprimir(all = false) {
      let final = [];
      let products = this.$parent.getProducts.data;

      if (all) {
        for (const order of this.printOrders) {
          const obj = {
            id: order.id,
            first_name: order.first_name,
            second_name: order.second_name,
            adress1: order.adress1,
            adress2: order.adress2,
            city: order.city,
            state: order.state,
            postcode: order.postcode,
            country: order.country,
            shipping_company: order.shipping_company.split('-')[0],
            articles: [],
            total: order.total,
            coin: order.coin,
            customer_note: order.customer_note,
            impressao: false,
          };
          for (const prod of JSON.parse(order.articles)) {
            let index = await products.findIndex((product) => product.sku == prod.sku);
            if (index != -1) {
              const obj_prod = {
                quantity: prod.quantity,
                name: prod.name,
                sku: prod.sku,
                ean: products[index].ean,
              };
              obj.articles.push(obj_prod);
            } else {
              const obj_prod = {
                quantity: prod.quantity,
                name: prod.name,
                sku: prod.sku,
                ean: 'Ean não associado',
              };
              obj.articles.push(obj_prod);
            }
          }
          final.push(obj);
        }
        try {
          await this.$store.dispatch('printOrders', { orders: final });
          this.$parent.updateChilds();
          this.select_print = '';
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$swal({
          title: 'Quer reimprimir as encomendas selecionadas?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            for (const order of this.printOrders) {
              const obj = {
                id: order.id,
                first_name: order.first_name,
                second_name: order.second_name,
                adress1: order.adress1,
                adress2: order.adress2,
                city: order.city,
                state: order.state,
                postcode: order.postcode,
                country: order.country,
                shipping_company: order.shipping_company.split('-')[0],
                articles: [],
                total: order.total,
                coin: order.coin,
                customer_note: order.customer_note,
                impressao: false,
              };
              for (const prod of JSON.parse(order.articles)) {
                let index = await products.findIndex((product) => product.sku == prod.sku);
                if (index != -1) {
                  const obj_prod = {
                    quantity: prod.quantity,
                    name: prod.name,
                    sku: prod.sku,
                    ean: products[index].ean,
                  };
                  obj.articles.push(obj_prod);
                } else {
                  const obj_prod = {
                    quantity: prod.quantity,
                    name: prod.name,
                    sku: prod.sku,
                    ean: 'Ean não associado',
                  };
                  obj.articles.push(obj_prod);
                }
              }
              final.push(obj);
            }
            try {
              await this.$store.dispatch('printOrders', { orders: final });
              this.$parent.updateChilds();
              this.select_print = '';
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
    async advance_all() {
      this.$swal({
        title: 'Quer colocar todas as encomendas no estado packing?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.printOrders = [];
          this.printOrders = this.printingOrders;
          await this.advanceToPacking(true);
        }
      });
    },
    async advanceToPacking(all = false) {
      let final = [];
      if (all) {
        for (const order of this.printOrders) {
          final.push(order.id);
        }
        try {
          await this.$store.dispatch('setPacking', { orders: final });
          this.$parent.updateChilds();
          this.select_print = '';
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$swal({
          title: 'Quer colocar as encomendas selecionadas no estado packing?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            for (const order of this.printOrders) {
              final.push(order.id);
            }
            try {
              await this.$store.dispatch('setPacking', { orders: final });
              this.$parent.updateChilds();
              this.select_print = '';
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
    async back_Picking(order) {
      this.$swal({
        title: 'Quer colocar a encomenda ' + order + ' no estado picking?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('setPicking', { id: order });
            await this.getOrdersPrintingFun();
            this.$swal('Completado', 'Estado alterado!', 'success');
          } catch (err) {
            console.log(err);
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters(['getOrdersPrinting']),
  },
  async created() {
    await this.getOrdersPrintingFun();
  },
};
</script>

<style>
#filters {
  padding: 0px 100px 0px 100px;
  text-align: left;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}
.advance {
  margin: 30px 5px 0px 5px;
  background-color: green;
  border-color: green;
}
.advance:hover {
  background-color: rgb(8, 97, 8);
  border-color: rgb(8, 97, 8);
}
.print {
  margin: 30px 5px 0px 5px;
  background-color: rgb(0, 98, 139);
  border-color: rgb(0, 98, 139);
}
.print:hover {
  background-color: rgb(0, 142, 202);
  border-color: rgb(0, 142, 202);
}
#setPendent {
  color: rgb(255, 0, 0);
}
#setPendent:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPriority {
  color: rgb(26, 100, 2);
}
#setPriority:hover {
  text-decoration: underline;
  cursor: pointer;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
.search {
  margin: 30px 80px 0px 80px;
  text-align: center;
}
.search label {
  font-weight: bold;
}
</style>
