<template>
  <!-- TODO O TEMPLATE FEITO UTILIZANDO O BOOTSTRAP VUE -->
  <div class="productslist">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <!-- BARRA DE PESQUISA -->
    <h2>Marcas</h2>
    <br />
    <!-- BARRA DE PESQUISA - PESQUISAR POR ENCOMENDA -->
    <div class="searchbar">
      <b-form-input
        class="float-right"
        align-v="center"
        v-model="filter"
        type="search"
        placeholder="Procurar Marca"
        label-cols-sm="3"
        label-align-sm="right"
        @input="pageOne()"
      >
        ></b-form-input
      >
    </div>
    <br />
    <br />
    <!-- PAGINAÇÃO NA PARTE SUPERIOR DA TABELA -->
    <!-- <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination> -->
    <!-- TABELA COM TODOS OS PRODUTOS -->
    <span v-if="this.role == 'admin'">
      <div class="table">
        <b-table
          striped
          hover
          :items="brands"
          :fields="fields"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          thead-class="text-white"
          small
        >
          <template #cell(other_names)="data">
            <a v-for="name in data.item.other_names" :key="name">
              {{ name }}
            </a>
          </template>
          <template #cell(price_options)="data">
            <v-select :options="options" v-model="data.item.price_options" label="price_options">
              <template slot="option" slot-scope="option"> {{ option.price_options }}</template>
            </v-select>
          </template>
          <template #cell(discount)="data">
            <input type="text" class="form-control form-control-sm" id="discount" v-model="data.item.discount" />
          </template>
          <template #cell(laboratories_id)="data">
            <v-select
              :options="labs"
              v-model="data.item.laboratories_name"
              item-values="name"
              label="name"
              @input="updateLab(data.item)"
            >
              <template slot="option" slot-scope="option"> {{ option.name }}</template>
            </v-select>
          </template>
          <template #cell(button)="data">
            <b-button v-b-modal.modal-extern id="openModal" class="btn btn-info" @click="updateBrand(data.item)"
              >🔄</b-button
            >
          </template>
        </b-table>
      </div>
    </span>
    <span v-else>
      <div class="table">
        <b-table
          striped
          hover
          :items="brands"
          :fields="fields2"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          thead-class="text-white"
          small
        >
          <template #cell(other_names)="data">
            <a v-for="name in data.item.other_names" :key="name">
              {{ name }}
            </a>
          </template>
          <template #cell(laboratories_id)="data">
            <v-select
              :options="labs"
              v-model="data.item.laboratories_name"
              item-values="name"
              label="name"
              @input="updateLab(data.item)"
            >
              <template slot="option" slot-scope="option"> {{ option.name }}</template>
            </v-select>
          </template>
        </b-table>
      </div>
    </span>
    <!-- PAGINAÇÃO NO INFERIO DA TABELA -->
    <!-- <b-pagination
      class="customPagination"
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      perPage: 100,
      currentPage: 1,
      filter: null,
      criteria: null,
      brands: [],
      rows: 1,
      labs: [],
      role: '',
      options: ['Ficheiro', 'Farmas', 'Nova Engel', 'Fornecedores'],
      fields2: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'other_names',
          label: 'Outros nomes',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'laboratories_id',
          label: 'Laboratório',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'other_names',
          label: 'Outros nomes',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'price_options',
          label: 'Opções de Preço',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'discount',
          label: 'Desconto',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'laboratories_id',
          label: 'Laboratório',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'button',
          label: '',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    /* FUNÇÃO ASSINCRONA QUE VAI À STORE BUSCAR OS PRODUTOS */
    async getAllBrands() {
      this.loading = true;
      try {
        await this.$store.dispatch('getAllBrands');
        this.brands = this.getBrands;
        this.perPage = this.brands.length;
        /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        this.rows = this.brands.length;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async updateBrand(data) {
      //updateBrands
      console.log(data);
      try {
        await this.$store.dispatch('updateBrands', {
          id: data.id,
          options: data.price_options ?? null,
          discount: data.discount ?? null,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Variáveis ${name} atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        alert(err);
      }
    },
    /* FILTRO PARA A BARRA DE PESQUISA */
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
    },
    pageOne() {
      this.currentPage = 1;
    },
    async getAllLabs() {
      this.loading = true;
      try {
        await this.$store.dispatch('allLaboratories');
        this.labs = this.getAllLaboratories;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async userType() {
      let user = JSON.parse(localStorage.getItem('user'));
      this.role = user.role;
      console.log('ROLEEEE', this.role);
    },
    async updateLab(data) {
      try {
        console.log(data.laboratories_name == null);
        if (data.laboratories_name == null) {
          data.laboratories_name = { id: null };
        }
        await this.$store.dispatch('updateBrandsLab', {
          id: data.id,
          lab: data.laboratories_name.id ?? null,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Marca ${data.name} atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
  },
  computed: {
    ...mapGetters(['getBrands']),
    ...mapGetters(['getAllLaboratories']),
  },
  async created() {
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    await this.getAllBrands();
    await this.getAllLabs();
    await this.userType();
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}

/* PONTOS ONDE SURGE A COR DO STOCK */
.dot-yellow {
  height: 20px;
  width: 20px;
  background-color: goldenrod;
  border-radius: 50%;
  display: inline-block;
}
.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

/* BARRA DE PESQUISA */
#filter {
  width: 200px;
  margin-right: 0;
}
.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}

.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}
</style>
