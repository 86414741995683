<template>
  <div>
    <h1>Packing</h1>
    <div class="search">
      <div class="row" id="filters">
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Encomenda</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              v-model="orderSearch"
              @change="routeToOrder()"
            />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Estado</span
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="status"
              @change="filterStatus()"
            >
              <option selected value="all">Todas</option>
              <option value="white">Branco (&#60; 2 dias)</option>
              <option value="blue">Azul (2 dias)</option>
              <option value="orange">Laranja (3 dias)</option>
              <option value="red">Vermelho (4 dias)</option>
              <option value="black">Preto (&#62; 4 dias)</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Transportadora</span
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="transportadora"
              @change="select_trans()"
            >
              <option selected value="">Todas</option>
              <option value="ctt">CTT</option>
              <option value="cttexpresso">CTT Expresso</option>
              <option value="dhl">DHL</option>
              <option value="fedex">FedEx</option>
              <option value="free">Free Shipping</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <b-table
      striped
      hover
      :items="filteredPackingOrders"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      class="table"
      small
      thead-class="text-white"
    >
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span
            v-if="data.item.priority == true"
            style="background-color: green; color: white"
            >{{ data.item.id }}</span
          >
          <span v-else>{{ data.item.id }}</span>
        </b-link>
        <br />
      </template>
      <template #cell(date)="data">
        <span
          v-if="calculateDifferenceBetweenDates(data.item.date) == 2"
          style="background: blue; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3"
          style="background: orange; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4"
          style="background: red; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span
          v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4"
          style="background: black; color: white"
        >
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span
          v-for="article in JSON.parse(data.item.articles)"
          :key="article.id"
        >
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(article)"
              >{{ article.name }}
            </a>
            - {{ article.price_eur }} €
          </p>
        </span>
        <a
          id="picking"
          @click="back_Printing(data.item.id, data.item.id_invoice)"
          >Voltar para printing</a
        >
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                          id="btnUpdateAddress"
                          v-b-modal.modal-1
                          @click="passDataModal(data.item)"
                          >✒️</b-button
                          > -->
            <a
              id="aUpdateAddress"
              v-b-modal.modal-1
              @click="passDataModal(data.item)"
              >✒️</a
            >
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a
            id="aUpdateAddress"
            v-b-modal.modal-2
            @click="passContact(data.item)"
            >📱</a
          >
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split("-")[0] }}
          </p>
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: function () {
    return {
      page: "",
      rows: "",
      perPage: 10,
      currentPage: 1,
      filter: null,
      packingOrders: [],
      filteredPackingOrders: [],
      fields: [
        {
          key: "id",
          label: "Nº Encmd.",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "date",
          label: "Data",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ",
        },
        {
          key: "articles",
          label: "Artigos",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "client",
          label: "Cliente",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "email",
          label: "Email",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "company",
          label: "Trans.",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
      ],
      status: "all",
      transportadora: "",
      orderSearch: "",
    };
  },
  methods: {
    async getPackingOrdersFun() {
      try {
        await this.$store.dispatch("getOrdersPacking");
        this.packingOrders = this.getOrdersPacking;
        this.filteredPackingOrders = this.packingOrders;
        this.perPage = this.packingOrders.length;
        this.rows = this.packingOrders.length;
      } catch (err) {
        console.log(err);
      }
    },
    routeToOrder(orderId) {
      if (orderId == undefined) {
        this.$parent.routeToOrder(this.orderSearch);
      } else {
        this.$parent.routeToOrder(orderId);
      }
    },
    calculateDifferenceBetweenDates(date) {
      let diff = this.$parent.calculateDifferenceBetweenDates(date);
      return Number(diff);
    },
    splitDate(date) {
      let split = date.split("T");
      return split[0];
    },

    //Modais
    async passDataModal(data) {
      await this.$parent.passDataModal(data);
    },
    async passContact(data) {
      await this.$parent.passContact(data);
    },
    async passProductData(data) {
      await this.$parent.passProductData(data);
    },

    //Specific
    async filterStatus() {
      if (this.status == "all") {
        this.filteredPackingOrders = this.packingOrders;
      } else if (this.status == "white") {
        this.filteredPackingOrders = this.packingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) < 2
        );
      } else if (this.status == "blue") {
        this.filteredPackingOrders = this.packingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 2
        );
      } else if (this.status == "orange") {
        this.filteredPackingOrders = this.packingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 3
        );
      } else if (this.status == "red") {
        this.filteredPackingOrders = this.packingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) == 4
        );
      } else if (this.status == "black") {
        this.filteredPackingOrders = this.packingOrders.filter(
          (order) => this.calculateDifferenceBetweenDates(order.date) > 4
        );
      }
    },
    async select_trans() {
      console.log(this.transportadora);
    },
    async imprimir(order) {
      let final = [];
      let products = this.$parent.getProducts.data;
      const obj = {
        id: order.id,
        first_name: order.first_name,
        second_name: order.second_name,
        adress1: order.adress1,
        adress2: order.adress2,
        city: order.city,
        state: order.state,
        postcode: order.postcode,
        country: order.country,
        shipping_company: order.shipping_company.split("-")[0],
        articles: [],
        total: order.total,
        coin: order.coin,
        customer_note: order.customer_note,
        impressao: false,
      };
      for (const prod of JSON.parse(order.articles)) {
        let index = await products.findIndex(
          (product) => product.sku == prod.sku
        );
        if (index != -1) {
          const obj_prod = {
            quantity: prod.quantity,
            name: prod.name,
            sku: prod.sku,
            ean: products[index].ean,
          };
          obj.articles.push(obj_prod);
        } else {
          const obj_prod = {
            quantity: prod.quantity,
            name: prod.name,
            sku: prod.sku,
            ean: "Ean não associado",
          };
          obj.articles.push(obj_prod);
        }
      }
      final.push(obj);
      try {
        await this.$store.dispatch("printOrders", { orders: final });
      } catch (err) {
        console.log(err);
      }
    },
    async back_Printing(order, invoice) {
      console.log(invoice);
      if (invoice != null && invoice != "") {
        this.$swal({
          title:
            "Esta encomenda tem fatura associada, quer colocar a encomenda " +
            order +
            " no estado printing?",
          icon: "question",
          text: "Ao colocar a encomenda no estado printing terá que fazer nota de crédito",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("internReturn", { id: order, newInvoice: false });
              await this.getPackingOrdersFun();
              this.$swal("Completado", "Estado alterado!", "success");
            } catch (err) {
              console.log(err);
            }
          }
        });
      } else {
        this.$swal({
          title: "Quer colocar a encomenda " + order + " no estado printing?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("setPrinting", { id: order });
              await this.getPackingOrdersFun();
              this.$swal("Completado", "Estado alterado!", "success");
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getOrdersPacking"]),
  },
  async created() {
    await this.getPackingOrdersFun();
  },
};
</script>

<style>
.table {
  font-size: 12px;
  margin-top: 30px;
}
#picking {
  color: darkkhaki;
}
#picking:hover {
  text-decoration: underline;
  cursor: pointer;
}
#imprimir {
  color: orangered;
}
#imprimir:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPendent {
  color: rgb(255, 0, 0);
}
#setPendent:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPriority {
  color: rgb(26, 100, 2);
}
#setPriority:hover {
  text-decoration: underline;
  cursor: pointer;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
#filters {
  padding: 0px 100px 0px 100px;
}
</style>
