<template>
  <div>
    <div class="row" id="filters">
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Nº Enc</span>
          </div>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="orderSearch" @change="filterOrders()" />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Nome</span>
          </div>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="nameSearch" @change="filterOrders()" />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Email</span>
          </div>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="emailSearch" @change="filterOrders()" />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Track ID</span>
          </div>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="trackingSearch" @change="filterOrders()" />
        </div>
      </div>
      <div class="col">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">Estado</span>
          </div>
          <select class="custom-select" id="inputGroupSelect01" v-model="statusSearch" @change="filterStatus">
            <option selected value="all">Todas</option>
            <option value="completed">Completa</option>
            <option value="cancelled">Canceladas</option>
            <option value="returned">Devolvida</option>
            <option value="confirmed/returned">Confirmada/Devolvida</option>
            <option value="lost_with_insurance">Perdida com Seguro</option>
            <option value="lost_without_insurance">Perdida sem Seguro</option>
          </select>
        </div>
      </div>
    </div>
    <b-table striped hover :items="filteredOrders" :fields="fields" :per-page="perPage" :current-page="currentPage" class="table" small thead-class="text-white">
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id, data.item.send_email_address)">{{ data.item.id }}</b-link>
      </template>
      <template #cell(date)="data">
        {{ splitDate(data.item.date) }}
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span v-for="article in JSON.parse(data.item.articles)" :key="article.id">
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a id="openArticle" v-b-modal.modal-3 @click="passProductData(article)">{{ article.name }} </a>
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country }}
          </p>
          <a id="aUpdateAddress" v-b-modal.modal-1 @click="passDataModal(data.item)">✒️</a>
        </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
          <a id="aUpdateAddress" v-b-modal.modal-2 @click="passContact(data.item)">📱</a>
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(moloni)="data">
        <span v-if="data.item.status == 'completed'">Completas</span>
        <span v-else-if="data.item.status == 'cancelled'">Canceladas</span>
        <span v-else-if="data.item.status == 'returned'">Devolvidas</span>
        <span v-else-if="data.item.status == 'processing'">Em processamento</span><span v-else-if="data.item.status == 'lost_with_insurance'">Perdida com Seguro</span>
        <span v-else-if="data.item.status == 'lost_without_insurance'">Perdida sem Seguro</span>
        <span v-if="data.item.delivery_company == ''"><p>Sem Tracking associado</p></span>
        <span v-else>
          <a @click="trackingFollow(data.item)">
            <p id="Tracking">{{ data.item.tracking_id }} ({{ data.item.delivery_company }})</p>
          </a>
          <span v-if="data.item.shipping_info != undefined" style="font-size: smaller">
            <p><b style="color: red">Preço Envio:</b> {{ data.item.shipping_info.price }} €</p>
            <p><b>Peso: </b> {{ data.item.shipping_info.weight }} | <b>Altura: </b> {{ data.item.shipping_info.height }}</p>
            <p><b>Largura: </b> {{ data.item.shipping_info.width }} | <b>Comprimento: </b> {{ data.item.shipping_info.lenght }}</p>
          </span>
        </span>
        <span v-if="data.item.insurance != null">Compensado: {{ data.item.insurance }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data: function () {
    return {
      page: '',
      rows: '',
      perPage: 10,
      currentPage: 1,
      filter: null,
      allFilteredOrders: [],
      filteredOrders: [],
      orderSearch: '',
      nameSearch: '',
      emailSearch: '',
      trackingSearch: '',
      statusSearch: 'all',
      fields: [
        {
          key: 'id',
          label: 'Nº Encmd.',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
          sortable: true,
        },
        {
          key: 'articles',
          label: 'Artigos',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'client',
          label: 'Cliente',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'company',
          label: 'Trans.',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'moloni',
          label: 'Tracking',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    async filterOrders() {
      if (this.orderSearch == '' && this.nameSearch == '' && this.emailSearch == '' && this.trackingSearch == '') {
        console.log('Não pesquisa');
        this.filteredOrders = [];
      } else {
        try {
          await this.$store.dispatch('fetchOrdersFilter', {
            id: this.orderSearch,
            name: this.nameSearch,
            email: this.emailSearch,
            tracking: this.trackingSearch,
          });
          this.allFilteredOrders = this.getFilterOrders;
          if (this.statusSearch == 'all') {
            this.filteredOrders = this.allFilteredOrders;
            this.perPage = this.filteredOrders.length;
          } else {
            this.filteredOrders = this.allFilteredOrders.filter((order) => order.status == this.statusSearch);
            this.perPage = this.filteredOrders.length;
          }
        } catch (err) {
          console.log(this.orderSearch, this.nameSearch, this.emailSearch);
          console.log(err);
          alert(err);
        }
      }
    },
    filterStatus() {
      if (this.statusSearch == 'all') {
        this.filteredOrders = this.allFilteredOrders;
        this.perPage = this.filteredOrders.length;
      } else {
        this.filteredOrders = this.allFilteredOrders.filter((order) => order.status == this.statusSearch);
        this.perPage = this.filteredOrders.length;
      }
    },
    routeToOrder(orderId, send_email_address) {
      this.$parent.routeToOrder(orderId, send_email_address);
    },
    calculateDifferenceBetweenDates(date) {
      let diff = this.$parent.calculateDifferenceBetweenDates(date);
      return Number(diff);
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    async trackingFollow(order) {
      console.log(order.delivery_company);
      let tracking;
      switch (order.delivery_company) {
        case 'dhl':
          tracking = `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${order.tracking_id}`;
          break;
        case 'nacex':
          tracking = `https://www.nacex.com/seguimientoDetalle.do?agencia_origen=7473&numero_albaran=${order.tracking_id}`;
          break;
        case 'ctt':
          tracking = `https://t.17track.net/pt#nums=${order.tracking_id}`;
          break;
        case 'cttExpresso':
          tracking = `https://t.17track.net/pt#nums=${order.tracking_id}`;
          break;
        case 'fedex':
          tracking = `https://www.fedex.com/fedextrack/?trknbr=${order.tracking_id}`;
          break;
        case 'correos':
          tracking = `https://www.cexpr.es/SeguimientoSinCP/search?n=${order.tracking_id}`;
          break;
        default:
      }
      window.open(tracking, '_blank');
    },
    //Modais
    async passDataModal(data) {
      this.$parent.passDataModal(data);
    },
    async passContact(data) {
      this.$parent.passContact(data);
    },
    async passProductData(data) {
      this.$parent.passProductData(data);
    },
  },
  computed: {
    ...mapGetters(['getFilterOrders']),
  },
  async created() {},
};
</script>

<style>
#filters {
  padding: 0px 100px 0px 100px;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}
#setPendent {
  color: rgb(255, 0, 0);
}
#setPendent:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPriority {
  color: rgb(26, 100, 2);
}
#setPriority:hover {
  text-decoration: underline;
  cursor: pointer;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
#Tracking:hover {
  cursor: pointer;
  text-decoration: underline;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
span a p {
  font-size: 14px;
  text-decoration: underline;
}
span a p:hover {
  text-decoration: none;
  cursor: pointer;
}
</style>
