<template>
  <!-- TODO O TEMPLATE FEITO UTILIZANDO O BOOTSTRAP VUE -->
  <div class="productslist">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <!-- BARRA DE PESQUISA -->
    <h2>Países</h2>
    <br />
    <!-- BARRA DE PESQUISA - PESQUISAR POR ENCOMENDA -->
    <div class="searchbar">
      <b-form-input
        class="float-right"
        align-v="center"
        v-model="filter"
        type="search"
        placeholder="Procurar País"
        label-cols-sm="3"
        label-align-sm="right"
        @input="pageOne()"
      >
        ></b-form-input
      >
    </div>
    <!-- PAGINAÇÃO NA PARTE SUPERIOR DA TABELA -->
    <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
    <!-- TABELA COM TODOS OS PRODUTOS -->
    <div class="table">
      <b-table
        striped
        hover
        :items="countries"
        :fields="fields"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        thead-class="text-white"
        small
      >
        <template #cell(tracking_ini)="data">
          <input type="text" class="form-control form-control-sm" id="tracking_ini" v-model="data.item.tracking_ini" />
        </template>
        <template #cell(tracking_final)="data">
          <input
            type="text"
            class="form-control form-control-sm"
            id="tracking_final"
            v-model="data.item.tracking_final"
            :disabled="data.item.tracking_single"
          />
        </template>
        <template #cell(tracking_single)="data">
          <b-form-checkbox v-model="data.item.tracking_single" name="check-button" switch size="lg"></b-form-checkbox>
        </template>
        <template #cell(tax_iva)="data">
          <input type="number" class="form-control form-control-sm" id="tracking_final" v-model="data.item.tax_iva" />
        </template>
        <template #cell(tax_iva_reduced)="data">
          <input
            type="number"
            class="form-control form-control-sm"
            id="tracking_final"
            v-model="data.item.tax_iva_reduced"
          />
        </template>
        <template #cell(button)="data">
          <b-button v-b-modal.modal-extern id="openModal" class="btn btn-info" @click="updateBrand(data.item)"
            >🔄</b-button
          >
        </template>
      </b-table>
    </div>
    <!-- PAGINAÇÃO NO INFERIO DA TABELA -->
    <b-pagination
      class="customPagination"
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      perPage: 100,
      currentPage: 1,
      filter: null,
      criteria: null,
      countries: [],
      rows: 1,
      fields: [
        {
          key: 'code',
          label: 'Código',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'coin',
          label: 'Moeda',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'tracking_ini',
          label: 'Tracking #1',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'tracking_final',
          label: 'Tracking #2',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'tracking_single',
          label: 'Tracking ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'tax_iva',
          label: 'Iva',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'tax_iva_reduced',
          label: 'Iva Reduzido',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'button',
          label: '',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    /* FUNÇÃO ASSINCRONA QUE VAI À STORE BUSCAR OS PRODUTOS */
    async getAllCountries_fun() {
      this.loading = true;
      try {
        await this.$store.dispatch('fetchAllCountries');
        this.countries = this.getAllCountries;
        /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        this.rows = this.countries.length;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async updateBrand(data) {
      try {
        if (data.tracking_single == true) {
          data.tracking_final = null;
        }
        await this.$store.dispatch('updateTrackingCountry', {
          country: data.code,
          tracking_ini: data.tracking_ini,
          tracking_final: data.tracking_final,
          tracking_single: data.tracking_single,
          tax_iva: data.tax_iva,
          tax_iva_reduced: data.tax_iva_reduced,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Variáveis ${data.code} atualizadas`,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        alert(err);
      }
    },
    /* FILTRO PARA A BARRA DE PESQUISA */
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
    },
    pageOne() {
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters(['getAllCountries']),
  },
  async created() {
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    await this.getAllCountries_fun();
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}

/* PONTOS ONDE SURGE A COR DO STOCK */
.dot-yellow {
  height: 20px;
  width: 20px;
  background-color: goldenrod;
  border-radius: 50%;
  display: inline-block;
}
.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

/* BARRA DE PESQUISA */
#filter {
  width: 200px;
  margin-right: 0;
}
.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}

.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}
</style>
