<template>
  <div class="orders">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <b-button class="returnOrder" @click="movement('primeiro')"
      >1º Trimestre</b-button
    >
    <b-button class="waitAddress" @click="movement('segundo')"
      >2º Trimestre</b-button
    >
    <b-button class="processing" @click="movement('terceiro')"
      >3º Trimestre</b-button
    >
    <b-button class="printing" @click="movement('quarto')"
      >4º Trimestre</b-button
    >
    <br />
    <div class="search">
      <div class="row" id="filters">
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >SKU</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              v-model="sku_select"
              @change="filterbySKU()"
            />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Nome</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              v-model="name_select"
              @change="filterbyName()"
            />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Data</span
              >
            </div>
            <date-picker-wrapper :key="'Unique'">
              <date-picker
                v-model="date"
                format="YYYY-MM-DD"
                value-type="format"
                :lang="lang"
                range
                @change="getTrans(date[0], date[1])"
              ></date-picker>
            </date-picker-wrapper>
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >País</span
              >
            </div>
            <v-select
              style="font-size: small; width: 89%"
              :required="!country"
              v-model="country"
              :options="countries"
              :reduce="(country) => country.code"
              label="name"
              @input="filterByCountry()"
            >
              <template slot="option" slot-scope="option">
                {{ option.name }} ({{ option.code }})
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.name }} ({{ option.code }})
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div>
      <input
        type="checkbox"
        @change="splitBycountry"
        v-model="splitCountry"
      /><span>Dividir por países</span>
    </div>
    <h3>{{ message }}</h3>
    <h6>({{ start_date }} // {{ end_date }})</h6>
    <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
    <div class="table">
      <b-table
        striped
        hover
        :items="showArray"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        thead-class="text-white"
        small
      >
        <template #cell(sku)="data">
          <a id="openArticle" @click="passProductData(data.item)"
            >{{ data.item.sku }}
          </a>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>

    <!-- Modal produtos -->
    <b-modal
      ref="modal_product"
      id="modal-3"
      :title="'Produto ' + this.skuProduct"
      size="lg"
      hide-footer
    >
      <div class="card">
        <b-container>
          <b-row align-v="center"
            ><b-col class="left">
              <!-- IMAGEM DO PRODUTO -->
              <img :src="imgProduct" />
            </b-col>
            <b-col>
              <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
              <p><span id="subtitle">EAN: </span> {{ eanProduct }}</p>
              <p><span id="subtitle">SKU: </span> {{ skuProduct }}</p>
              <p><span id="subtitle">HS Code: </span> {{ hsProduct }}</p>
              <p>
                <span id="subtitle">Notas Arm.: </span> {{ warehouseProduct }}
              </p>
              <p><span id="subtitle">Notas: </span> {{ notesProduct }}</p>
              <p><span id="subtitle">Peso: </span> {{ weightProduct }} (g)</p>
            </b-col></b-row
          >
        </b-container>
        <button class="btn btn-info" @click="openProdPage()">
          Editar Produto
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      perPage: 100,
      currentPage: 1,
      rows: 1,
      fields: [
        {
          key: "sku",
          label: "SKU",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "name",
          label: "Nome",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "vendas",
          label: "Vendidos",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "stock_atual",
          label: "Stock Atual",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
      showArray: [],
      first_trimester: [],
      second_trimester: [],
      third_trimester: [],
      fourth_trimester: [],
      picked_trimester: [],
      first_trimester_filtered: [],
      second_trimester_filtered: [],
      third_trimester_filtered: [],
      fourth_trimester_filtered: [],
      picked_trimester_filtered: [],
      message: "",
      start_date: "",
      end_date: "",
      year_filter: "",
      //Filter
      sku_select: "",
      name_select: "",
      date: "",
      // Products
      products: [],
      eanProduct: "",
      skuProduct: "",
      hsProduct: "",
      warehouseProduct: "",
      notesProduct: "",
      weightProduct: "",
      imgProduct: "",
      idProduct: "",
      stockProduct: "",
      countries: [],
      country: "",
      splitCountry: false,
      //Date Picker
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
          ],
          // MMM
          monthsShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ],
          // dddd
          weekdays: [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
          ],
          // ddd
          weekdaysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          // dd
          weekdaysMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        },
      },
    };
  },
  methods: {
    //Gerais
    async movement(new_page) {
      if (new_page == "primeiro") {
        this.date = "";
        this.message = "1º Trimestre";
        this.start_date = this.first_trimester.initial_date;
        this.end_date = this.first_trimester.end_date;
        this.showArray = this.first_trimester_filtered;
        this.rows = this.showArray.length;
      } else if (new_page == "segundo") {
        this.date = "";
        this.message = "2º Trimestre";
        this.start_date = this.second_trimester.initial_date;
        this.end_date = this.second_trimester.end_date;
        this.showArray = this.second_trimester_filtered;
        this.rows = this.showArray.length;
      } else if (new_page == "terceiro") {
        this.date = "";
        this.message = "3º Trimestre";
        this.start_date = this.third_trimester.initial_date;
        this.end_date = this.third_trimester.end_date;
        this.showArray = this.third_trimester_filtered;
        this.rows = this.showArray.length;
      } else if (new_page == "quarto") {
        this.date = "";
        this.message = "4º Trimestre";
        this.start_date = this.fourth_trimester.initial_date;
        this.end_date = this.fourth_trimester.end_date;
        this.showArray = this.fourth_trimester_filtered;
        this.rows = this.showArray.length;
      }
    },
    async getSalesLastYear() {
      try {
        await this.$store.dispatch("salesReport");
        this.first_trimester = this.getSalesReport.first_trimester;
        this.second_trimester = this.getSalesReport.second_trimester;
        this.third_trimester = this.getSalesReport.third_trimester;
        this.fourth_trimester = this.getSalesReport.fourth_trimester;
        this.first_trimester_filtered = this.first_trimester.result;
        this.second_trimester_filtered = this.second_trimester.result;
        this.third_trimester_filtered = this.third_trimester.result;
        this.fourth_trimester_filtered = this.fourth_trimester.result;
        console.log(this.getSalesReport);
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async getSalesAnual() {
      try {
        await this.$store.dispatch("salesReport", {
          year: this.year_filter,
        });
        this.first_trimester = this.getAnualSalesReport.first_trimester;
        this.second_trimester = this.getAnualSalesReport.second_trimester;
        this.third_trimester = this.getAnualSalesReport.third_trimester;
        this.fourth_trimester = this.getAnualSalesReport.fourth_trimester;
        console.log(this.getSalesReport);
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },

    //Filters
    async filterbySKU() {
      this.name_select = "";
      if (this.sku_select == "") {
        this.first_trimester_filtered = this.first_trimester.result;
        this.second_trimester_filtered = this.second_trimester.result;
        this.third_trimester_filtered = this.third_trimester.result;
        this.fourth_trimester_filtered = this.fourth_trimester.result;
        this.picked_trimester_filtered = this.picked_trimester.result;
        if (this.message == "1º Trimestre") {
          this.showArray = this.first_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "2º Trimestre") {
          this.showArray = this.second_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "3º Trimestre") {
          this.showArray = this.third_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "4º Trimestre") {
          this.showArray = this.fourth_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "Datas") {
          this.showArray = this.picked_trimester_filtered;
          this.rows = this.showArray.length;
        }
      } else {
        this.first_trimester_filtered = this.first_trimester.result.filter(
          (prod) => prod.sku == this.sku_select
        );
        this.second_trimester_filtered = this.second_trimester.result.filter(
          (prod) => prod.sku == this.sku_select
        );
        this.third_trimester_filtered = this.third_trimester.result.filter(
          (prod) => prod.sku == this.sku_select
        );
        this.fourth_trimester_filtered = this.fourth_trimester.result.filter(
          (prod) => prod.sku == this.sku_select
        );
        if (this.date != "") {
          this.picked_trimester_filtered = this.picked_trimester.result.filter(
            (prod) => prod.sku == this.sku_select
          );
        }
        if (this.message == "1º Trimestre") {
          this.showArray = this.first_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "2º Trimestre") {
          this.showArray = this.second_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "3º Trimestre") {
          this.showArray = this.third_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "4º Trimestre") {
          this.showArray = this.fourth_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "Datas") {
          this.showArray = this.picked_trimester_filtered;
          this.rows = this.showArray.length;
        }
      }
    },
    async filterbyName() {
      this.sku_select = "";
      if (this.name_select == "") {
        this.first_trimester_filtered = this.first_trimester.result;
        this.second_trimester_filtered = this.second_trimester.result;
        this.third_trimester_filtered = this.third_trimester.result;
        this.fourth_trimester_filtered = this.fourth_trimester.result;
        this.picked_trimester_filtered = this.picked_trimester.result;
        if (this.message == "1º Trimestre") {
          this.showArray = this.first_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "2º Trimestre") {
          this.showArray = this.second_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "3º Trimestre") {
          this.showArray = this.third_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "4º Trimestre") {
          this.showArray = this.fourth_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "Datas") {
          this.showArray = this.picked_trimester_filtered;
          this.rows = this.showArray.length;
        }
      } else {
        console.log(this.first_trimester);
        this.first_trimester_filtered = this.first_trimester.result.filter(
          (prod) =>
            prod.name
              .toLowerCase()
              .includes(this.name_select.toLocaleLowerCase())
        );
        this.second_trimester_filtered = this.second_trimester.result.filter(
          (prod) =>
            prod.name
              .toLowerCase()
              .includes(this.name_select.toLocaleLowerCase())
        );
        this.third_trimester_filtered = this.third_trimester.result.filter(
          (prod) =>
            prod.name
              .toLowerCase()
              .includes(this.name_select.toLocaleLowerCase())
        );
        this.fourth_trimester_filtered = this.fourth_trimester.result.filter(
          (prod) =>
            prod.name
              .toLowerCase()
              .includes(this.name_select.toLocaleLowerCase())
        );
        if (this.date != "") {
          this.picked_trimester_filtered = this.picked_trimester.result.filter(
            (prod) =>
              prod.name.toLowerCase().includes(this.name_select.toLowerCase())
          );
          console.log(this.first_trimester_filtered);
        }
        if (this.message == "1º Trimestre") {
          this.showArray = this.first_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "2º Trimestre") {
          this.showArray = this.second_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "3º Trimestre") {
          this.showArray = this.third_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "4º Trimestre") {
          this.showArray = this.fourth_trimester_filtered;
          this.rows = this.showArray.length;
        } else if (this.message == "Datas") {
          this.showArray = this.picked_trimester_filtered;
          this.rows = this.showArray.length;
        }
      }
    },
    async getTrans(data_i, data_f) {
      if (
        this.country != "" &&
        this.country != undefined &&
        this.country != null
      ) {
        await this.filterByCountry();
      } else if (data_i == undefined || data_f == undefined) {
        this.message = "1º Trimestre";
        this.start_date = this.first_trimester.initial_date;
        this.end_date = this.first_trimester.end_date;
        this.showArray = this.first_trimester.result;
        this.rows = this.showArray.length;
      } else {
        try {
          this.sku_select = "";
          await this.$store.dispatch("salesReportPicked", {
            start_date: data_i,
            final_date: data_f,
          });
          this.message = "Datas";
          this.picked_trimester = this.getPickedSalesReport;
          this.picked_trimester_filtered = this.picked_trimester.result;
          this.start_date = this.picked_trimester.initial_date;
          this.end_date = this.picked_trimester.end_date;
          this.showArray = this.picked_trimester_filtered;
          this.rows = this.showArray.length;
        } catch (err) {
          alert(err);
        }
      }
    },

    //Modal
    async getAllProducts() {
      try {
        await this.$store.dispatch("fetchProducts");
        this.products = this.getProducts.data;
      } catch (err) {
        alert(err);
      }
    },
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        "_blank"
      );
      this.$refs["modal_product"].hide();
    },
    async passProductData(data) {
      window.open(
        `https://${window.location.host}/product/${data.id_prod}`,
        "_blank"
      );
    },

    async splitBycountry() {
      this.country = "";
      await this.filterByCountry(this.splitCountry);
      if (this.splitCountry == true) {
        this.fields.push({
          key: "country",
          label: "País",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        });
      } else {
        this.fields = this.fields.filter((field) => field.key !== "country");
      }
    },

    async filterByCountry(split = false) {
      this.loading = true;
      console.log(this.country);
      if (
        (this.country == "" ||
          this.country == undefined ||
          this.country == null) &&
        split == false
      ) {
        if (this.date[0] == undefined || this.date[1] == undefined) {
          await this.getSalesLastYear();
          await this.movement("primeiro");
        } else {
          await this.getTrans(this.date[0], this.date[1]);
        }
      } else {
        if (this.date[0] == undefined || this.date[1] == undefined) {
          try {
            await this.$store.dispatch("salesReportByCountry", {
              country: this.country,
            });
            this.first_trimester = this.getSalesReport.first_trimester;
            this.second_trimester = this.getSalesReport.second_trimester;
            this.third_trimester = this.getSalesReport.third_trimester;
            this.fourth_trimester = this.getSalesReport.fourth_trimester;
            this.first_trimester_filtered = this.first_trimester.result;
            this.second_trimester_filtered = this.second_trimester.result;
            this.third_trimester_filtered = this.third_trimester.result;
            this.fourth_trimester_filtered = this.fourth_trimester.result;
            console.log(this.getSalesReport);
            await this.movement("primeiro");
          } catch (err) {
            console.log(err);
            alert(err);
          }
        } else {
          await this.filterByCountryDate();
        }
      }
      if (this.sku_select != "") {
        await this.filterbySKU();
      }
      this.loading = false;
    },

    async filterByCountryDate() {
      try {
        await this.$store.dispatch("salesReportByCountryDate", {
          country: this.country,
          start_date: this.date[0],
          final_date: this.date[1],
        });
        this.message = "Datas";
        this.picked_trimester = this.getPickedSalesReport;
        this.picked_trimester_filtered = this.picked_trimester.result;
        this.start_date = this.picked_trimester.initial_date;
        this.end_date = this.picked_trimester.end_date;
        this.showArray = this.picked_trimester_filtered;
        this.rows = this.showArray.length;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async allCountries() {
      try {
        await this.$store.dispatch("fetchAllCountries");
        for (let index = 0; index < this.getAllCountries.length; index++) {
          const element = this.getAllCountries[index];
          this.countries.push({
            name: element.name,
            code: element.code,
            coin: element.coin,
          });
        }
        console.log(this.countries);
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    ...mapGetters(["getSalesReport"]),
    ...mapGetters(["getAnualSalesReport"]),
    ...mapGetters(["getPickedSalesReport"]),
    ...mapGetters(["getProducts"]),
    ...mapGetters(["getAllCountries"]),
  },
  async created() {
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    this.loading = true;
    await this.getSalesLastYear();
    // await this.getAllProducts();
    await this.movement("primeiro");
    await this.allCountries();
    this.loading = false;
  },
};
</script>

<style>
.orders {
  text-align: center;
}

.returnOrder {
  margin: 15px 5px 15px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.returnOrder:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress {
  margin: 15px 5px 15px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
.processing {
  margin: 15px 5px 15px 5px;
  background-color: #527d9c;
  border-color: #527d9c;
}
.processing:hover {
  background-color: #316484;
  border-color: #316484;
}
.printing {
  margin: 15px 5px 15px 5px;
  background-color: #316484;
  border-color: #316484;
}
.printing:hover {
  background-color: #004c6d;
  border-color: #004c6d;
}

.table {
  font-size: 12px;
  margin-top: 30px;
}

/* PESQUISA */
#filters {
  padding: 0px 100px 0px 100px;
  text-align: left;
}

/* MODAL PRODUTOS */
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
img {
  max-width: 100%;
  border-radius: 18px;
}

/* UNDERLINES */
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
