<template>
  <!-- TODO O TEMPLATE FEITO UTILIZANDO O BOOTSTRAP VUE -->
  <div class="productslist">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <!-- BARRA DE PESQUISA -->
    <h2>Fornecedores</h2>
    <br />
    <!-- BARRA DE PESQUISA - PESQUISAR POR ENCOMENDA -->
    <div class="searchbar">
      <b-form-input class="float-right" align-v="center" v-model="filter" type="search" placeholder="Procurar Encomenda" label-cols-sm="3" label-align-sm="right"> ></b-form-input>
    </div>
    <!-- PAGINAÇÃO NA PARTE SUPERIOR DA TABELA -->
    <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage" aria-controls="my-table" align="center"></b-pagination>
    <b-button class="btn btn-success" v-b-modal.modal-add @click="modalAdd()">Adicionar Fornecedor</b-button>
    <!-- TABELA COM TODOS OS PRODUTOS -->
    <div class="table">
      <b-table striped hover :items="suppliers" :fields="fields" :filter="filter" :per-page="perPage" :current-page="currentPage" thead-class="text-white" small>
        <template #cell(url)="data">
          <a>{{ data.item.url }}</a>
        </template>
        <template #cell(invoice_type)="data">
          <v-select @input="updateInvoiceType(data.item)" :options="options" v-model="data.item.invoice_type" label="invoice_type">
            <template slot="option" slot-scope="option"> {{ option.invoice_type }}</template>
          </v-select>
        </template>
        <template #cell(button)="data">
          <b-button
            v-b-modal.modal-extern
            id="openModal"
            class="btn btn-info"
            @click="modalUpdate(data.item.id, data.item.name, data.item.other_name, data.item.contact, data.item.email, data.item.nif, data.item.account, data.item.password, data.item.url)"
            >Atualizar</b-button
          >
        </template>
      </b-table>
    </div>
    <!-- PAGINAÇÃO NO INFERIO DA TABELA -->
    <b-pagination class="customPagination" v-model="currentPage" pills :total-rows="rows" :per-page="perPage" aria-controls="my-table" align="center"></b-pagination>
    <b-modal id="modal-extern" title="Atualizar Fornecedor!" ref="modal_externSupplier" hide-footer>
      <form v-on:submit.prevent="updateSupplier()">
        <div class="form-group">
          <label for="sku">Nome</label>
          <input type="text" class="form-control" id="modal_name" v-model="modal_name" />
        </div>
        <div class="form-group">
          <label for="sku">Outro Nome</label>
          <input type="text" class="form-control" id="modal_sku" v-model="modal_other_name" />
        </div>
        <div class="form-group"></div>
        <div class="form-group">
          <label for="modal_qty_order">Contacto</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_contact" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">Email</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_email" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">NIF</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_nif" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">URL</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_url" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">Conta Username</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_account" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">Password</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_password" />
        </div>
        <button class="btn btn-primary" type="submit">Atualizar</button>
      </form>
    </b-modal>
    <b-modal id="modal-add" title="Adicionar Fornecedor!" ref="modal_add" hide-footer>
      <form v-on:submit.prevent="addSupplier()">
        <div class="form-group">
          <label for="sku">Nome</label>
          <input type="text" class="form-control" id="modal_name" v-model="modal_name" />
        </div>
        <div class="form-group">
          <label for="sku">Outro Nome</label>
          <input type="text" class="form-control" id="modal_sku" v-model="modal_other_name" />
        </div>
        <div class="form-group"></div>
        <div class="form-group">
          <label for="modal_qty_order">Contacto</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_contact" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">Email</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_email" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">NIF</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_nif" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">URL</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_url" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">Conta Username</label>
          <input type="text" class="form-control" id="modal_qty_account" v-model="modal_account" />
        </div>
        <div class="form-group">
          <label for="modal_qty_order">Password</label>
          <input type="text" class="form-control" id="modal_qty_order" v-model="modal_password" />
        </div>
        <button class="btn btn-primary" type="submit">Adicionar</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      perPage: 20,
      currentPage: 1,
      filter: null,
      criteria: null,
      suppliers: [],
      rows: 1,
      modal_id: '',
      modal_name: '',
      modal_other_name: '',
      modal_contact: '',
      modal_email: '',
      modal_nif: '',
      modal_password: '',
      modal_account: '',
      modal_url: '',
      options: ['Papel', 'Email', 'Email + Website', 'Website', 'Não envia automaticamente (pedir por e-mail)', 'Outro'],
      fields: [
        {
          key: 'name',
          label: 'Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'other_name',
          label: 'Outro Nome',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'contact',
          label: 'Contacto',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'nif',
          label: 'NIF',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'url',
          label: 'URL',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'account',
          label: 'Conta',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'password',
          label: 'Pass',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'invoice_type',
          label: 'Fatura',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'button',
          label: ' ',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    /* FUNÇÃO ASSINCRONA QUE VAI À STORE BUSCAR OS PRODUTOS */
    async getAllSuppliers() {
      this.loading = true;
      try {
        await this.$store.dispatch('getAllDataSuppliers');
        this.suppliers = this.getSuppliers;
        /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        this.rows = this.suppliers.length;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    handleFilter(val) {
      if (val.length >= 5 || val.length == 0) {
        clearTimeout(this.$_timeout);
        this.$_timeout = setTimeout(() => {
          this.criteria = val;
        }, 300);
      } // set this value to your preferred debounce timeout
    },
    modalUpdate(id, name, other_name, contact, email, nif, account, password, url) {
      this.modal_id = id;
      this.modal_name = name;
      this.modal_other_name = other_name;
      this.modal_contact = contact;
      this.modal_email = email;
      this.modal_nif = nif;
      this.modal_account = account;
      this.modal_password = password;
      this.modal_url = url;
    },
    modalAdd() {
      this.modal_id = '';
      this.modal_name = '';
      this.modal_other_name = '';
      this.modal_contact = '';
      this.modal_email = '';
      this.modal_nif = '';
      this.modal_account = '';
      this.modal_password = '';
      this.modal_url = '';
    },
    async updateSupplier() {
      try {
        await this.$store.dispatch('updateDataSuppliers', {
          id: this.modal_id,
          name: this.modal_name,
          other_name: this.modal_other_name,
          email: this.modal_email,
          contact: this.modal_contact,
          nif: this.modal_nif,
          account: this.modal_account,
          password: this.modal_password,
          url: this.modal_url,
        });
      } catch (err) {
        alert(err);
      }
      this.$refs['modal_externSupplier'].hide();
      this.getAllSuppliers();
    },
    async addSupplier() {
      try {
        await this.$store.dispatch('addAllDataSuppliers', {
          name: this.modal_name,
          other_name: this.modal_other_name,
          email: this.modal_email,
          contact: this.modal_contact,
          nif: this.modal_nif,
          account: this.modal_account,
          password: this.modal_password,
          url: this.modal_url,
        });
      } catch (err) {
        alert(err);
      }
      this.$refs['modal_add'].hide();
      this.getAllSuppliers();
    },
    async updateInvoiceType(data) {
      console.log('ENTREI');
      try {
        await this.$store.dispatch('updateDataSuppliersInvoiceType', {
          id: data.id,
          type: data.invoice_type,
        });
      } catch (err) {
        alert(err);
      }
      this.getAllSuppliers();
    },
    /* FILTRO PARA A BARRA DE PESQUISA */
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters(['getSuppliers']),
  },
  async created() {
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    await this.getAllSuppliers();
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}

/* PONTOS ONDE SURGE A COR DO STOCK */
.dot-yellow {
  height: 20px;
  width: 20px;
  background-color: goldenrod;
  border-radius: 50%;
  display: inline-block;
}
.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

/* BARRA DE PESQUISA */
#filter {
  width: 200px;
  margin-right: 0;
}
.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}

.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}
</style>
