<template>
  <div>
    <b-table striped hover :items="processingOrders" :fields="fields" :filter="filter" :per-page="perPage" :current-page="currentPage" class="table" small thead-class="text-white">
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span v-if="data.item.priority == true" style="background-color: green; color: white">{{ data.item.id }}</span>
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(date)="data">
        <span v-if="calculateDifferenceBetweenDates(data.item.date) == 2" style="background: blue; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else-if="calculateDifferenceBetweenDates(data.item.date) == 3" style="background: orange; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else-if="calculateDifferenceBetweenDates(data.item.date) == 4" style="background: red; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else-if="calculateDifferenceBetweenDates(data.item.date) > 4" style="background: black; color: white">
          {{ splitDate(data.item.date) }}
        </span>
        <span v-else style="background: white">
          {{ splitDate(data.item.date) }}
        </span>
      </template>
      <template #cell(return_date)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span>
          {{ splitDate(data.item.return_date) }}
        </span>
      </template>
      <template #cell(articles)="data">
        <!-- CICLO for PARA LER TODOS OS ARTIGOS DAS ENCOMENDAS  (JSON.parse UTILIZADO PORQUE RECEBEMOS EM STRING OS DADOS, TEMOS QUE O PASSAR PARA OBJETO) -->
        <span v-for="article in JSON.parse(data.item.articles)" :key="article.id">
          <p>
            {{ article.quantity }} x {{ article.sku }} -
            <a id="openArticle" v-b-modal.modal-3 @click="passProductData(article)">{{ article.name }} </a>
          </p>
        </span>
      </template>
      <template #cell(client)="data">
        <span>
          <p>
            <b>Nome:</b> {{ data.item.first_name }}
            {{ data.item.second_name }}
            <br />
            {{ data.item.adress1 }} {{ data.item.adress2 }} <br />
            {{ data.item.city }} - {{ data.item.postcode }}<br />
            {{ data.item.state }} - {{ data.item.country
            }}<!-- <b-button
                          id="btnUpdateAddress"
                          v-b-modal.modal-1
                          @click="passDataModal(data.item)"
                          >✒️</b-button
                          > -->
            <br />
          </p>
        </span>
        <span> </span>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.item.email }} <br />
          {{ data.item.phone }} <br />
        </span>
      </template>
      <template #cell(company)="data">
        <span>
          <p>
            {{ data.item.shipping_company.split('-')[0] }}
          </p>
        </span>
      </template>
      <template #cell(moloni)="data">
        <span v-if="data.item.send_email != true">
          <p>Não existe Tracking</p>
        </span>
        <span v-else
          ><a id="tracking" @click="trackingFollow(data.item)"
            ><p>Tracking Encomenda ({{ data.item.delivery_company }})</p></a
          >
          <!-- </span> -->
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data: function () {
    return {
      page: '',
      rows: '',
      perPage: 10,
      currentPage: 1,
      filter: null,
      processingOrders: [],
      fields: [
        {
          key: 'id',
          label: 'Nº Encmd.',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Data',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
          sortable: true,
        },
        {
          key: 'return_date',
          label: 'Data Devolução',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
          sortable: true,
        },
        {
          key: 'articles',
          label: 'Artigos',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'client',
          label: 'Cliente',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'company',
          label: 'Trans.',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: false,
        },
        {
          key: 'moloni',
          label: 'Tracking',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    async getOrdersReturnedFun() {
      try {
        await this.$store.dispatch('getOrdersReturned');
        this.processingOrders = this.getOrdersReturned;
        this.perPage = this.processingOrders.length;
        this.rows = this.processingOrders.length;
      } catch (err) {
        console.log(err);
      }
    },
    routeToOrder(orderId) {
      this.$parent.routeToOrder(orderId);
    },
    calculateDifferenceBetweenDates(date) {
      let diff = this.$parent.calculateDifferenceBetweenDates(date);
      return Number(diff);
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    async trackingFollow(order) {
      let tracking;
      switch (order.delivery_company) {
        case 'dhl':
          tracking = `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${order.tracking_id}`;
          break;
        case 'nacex':
          tracking = `https://www.nacex.com/seguimientoDetalle.do?agencia_origen=7473&numero_albaran=${order.tracking_id}`;
          break;
        case 'ctt':
          tracking = `https://www.ordertracker.com/track/${order.tracking_id}`;
          break;
        case 'fedex':
          tracking = `https://www.fedex.com/fedextrack/?trknbr=${order.tracking_id}`;
          break;
        case 'correos':
          tracking = `https://www.cexpr.es/SeguimientoSinCP/search?n=${order.tracking_id}`;
          break;
        default:
      }
      window.open(tracking, '_blank');
    },

    //Modais
    async passDataModal(data) {
      await this.$parent.passDataModal(data);
      await this.getOrdersReturnedFun();
    },
    async passContact(data) {
      await this.$parent.passContact(data);
      await this.getOrdersReturnedFun();
    },
    async passProductData(data) {
      await this.$parent.passProductData(data);
    },
  },
  computed: {
    ...mapGetters(['getOrdersReturned']),
  },
  async created() {
    await this.getOrdersReturnedFun();
  },
};
</script>

<style>
.table {
  font-size: 12px;
  margin-top: 30px;
}
span a p {
  text-decoration: underline;
}
span a p:hover {
  text-decoration: none;
  cursor: pointer;
}
#setPendent {
  color: rgb(255, 0, 0);
}
#setPendent:hover {
  text-decoration: underline;
  cursor: pointer;
}
#setPriority {
  color: rgb(26, 100, 2);
}
#setPriority:hover {
  text-decoration: underline;
  cursor: pointer;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
</style>
